import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { slugs } from "../../../Constant/slugs";
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../Constant/api";
import Select from 'react-select'

const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
    width: "100px",
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true
  },
  {
    id: 32,
    name: "Live",
    selector: (row) => row.meta.class_link.includes("https://") ? "Yes" : "",
    sortable: true,
    compact: true,
    width: "100px",
    reorder: true
  },
  {
    id: 32,
    name: "Batches",
    compact: true,
    selector: (row) =>
      <div>
        {JSON.parse(row.meta.batches).map((item, index) => (
          <div key={index}>
            {item.name}
          </div>
        ))}
      </div>,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "View",
    cell: (row) => <Link to={`${slugs.chapter_details}${row.slug}`}>View</Link>,

  }
];


const categories = [
  "GK",
  "Bank",
  "EPS"
]
export default function AllChapter() {
  const [Projects1, setProjects1] = React.useState([])
  const [serachName, setSearchName] = React.useState("")
  const [choose, setChoose] = React.useState(0)
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [Extra, setExtra] = React.useState([])

  const [Class, setClass] = React.useState([])
  const [Section, setSection] = React.useState([])
  const [ClassModal, setClassModal] = React.useState(false)
  const [SectionModal, setSectionModal] = React.useState(false)
  const [ClassSelect, setClassSelect] = React.useState("")
  const [SectionSelect, setSectionSelect] = React.useState("")
  const [SectionSelectID, setSectionSelectID] = React.useState("")
  const [ClassSelectId, setClassSelectId] = React.useState("")

  const [CategoryNew, setCategoryNew] = React.useState([])
  const [CategoryNewIndex, setCategoryNewIndex] = React.useState("")
  const [CategoryName, setCategoryName] = React.useState("")
  const [CategoryNewCache, setCategoryNewCache] = React.useState([])
  const [Projects2, setProjects2] = React.useState([])
  const [AddNew, setAddNew] = React.useState(false)
  const [deleteUsersModal, setDeleteUsersModal] = React.useState(false)
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_chapters)
      .then((res) => {
        setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllProjects({
      per_page: 1000,
      _fields: "id,title,slug,meta"
    })
    GetAllCategories()
    GetAllClass()
    GetAllSection()

  }, [])
  const GetAllCategories = () => {
    setReload(true)
    AdsGETApiAuth({
      _fields: "id,title,slug,meta"
    }, `${slugs.db_slug_extra}/6447`)
      .then((res) => {
        setCategoryNew(JSON.parse(res.data.meta.listing_value))
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
      })
  }
  const GetAllClass = () => {
    setReload(true)
    AdsGETApiAuth({
      _fields: "id,title,slug,meta",
      per_page: 1000
    }, `${slugs.db_slug_category}`)
      .then((res) => {
        setClass(res.data)
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
      })
  }
  const GetAllSection = () => {
    setReload(true)
    AdsGETApiAuth({
      _fields: "id,title,slug,meta",
      per_page: 1000
    }, `${slugs.db_slug_batch}`)
      .then((res) => {
        setSection(res.data)
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
      })
  }
  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Materials</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Materials
        </div>
      </div>
      <a href={slugs.new_chapter}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {
          }}
        >Add New Materials</div>
      </a>
      <div className="row">
        <div style={{ display: "inline", cursor: "pointer" }}>
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setChoose(0)
            }}
          >
            Filter | &nbsp;
          </div>
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setProjects1(CustomersList)
              setChoose(1)
            }}
          >
            All Materials | &nbsp;
          </div>

          {choose == 0 &&
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setAddNew(true)
                setCategoryNewIndex(`item-${Object.values(CategoryNew).length}`)
                var cacheValue = {
                  ...CategoryNew,
                  [`item-${Object.values(CategoryNew).length}`]: {
                    "id": `${Object.values(CategoryNew).length}`,
                    "name": ""
                  }
                }
                setCategoryNewCache(cacheValue)
                setCategoryName("")
              }}
            >
              Add New Section
            </div>
          }
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
        </div>
        <div className="col-md-6 row">
          <div className="col-4" />
          <input className="col-6" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachName} placeholder="Name"
            onChange={(event) => {
              setChoose(1)
              setSearchName(event.target.value)
              setProjects1(CustomersList.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
            }}
          />
        </div>
      </div>
      {Projects2.length !== 0 &&
        <div style={{ display: "inline-block", margin: "0px 5px" }}
          onClick={() => {
            setDeleteUsersModal(true)
          }}
        >
          Delete Material
        </div>
      }
      <div style={{ padding: "10px", margin: "10px" }}>
        {choose == 0 &&
          <>
            <div style={{ padding: 20, backgroundColor: "#fff" }}>
              <div>
                Class : {ClassSelect}  <span style={{ cursor: "pointer", border: "1px solid black", borderRadius: 5, padding: "10px" }}
                  onClick={() => {
                    setClassModal(true)
                    setSectionSelect("")
                  }}
                >Choose</span>
              </div>
              <br />
              {ClassSelect !== "" &&
                <>
                  <div>
                    Section : {SectionSelect.replace(`-${ClassSelect}`, "")}
                    <span style={{ cursor: "pointer", border: "1px solid black", borderRadius: 5, padding: "10px" }}
                      onClick={() => {
                        setSectionModal(true)
                      }}
                    >Choose</span>
                  </div>
                  <br />
                  <div>
                    <span style={{ cursor: "pointer", border: "1px solid black", borderRadius: 5, padding: 10 }}
                      onClick={() => {
                        if (SectionSelect == "")
                          setProjects1(CustomersList.filter(a => a.meta.batches.includes(`${ClassSelect}`)))
                        else
                          setProjects1(CustomersList.filter(a => a.meta.batches.includes(`${SectionSelect}`)))
                        setChoose(1)
                      }}
                    >View Materials</span>
                  </div>
                </>
              }
              {ClassModal &&
                <section className="modal" >
                  <div className="row">
                    <div className="col-md-4 col-1"></div>
                    <div className="col-md-4 col-10 shadow bg-white">
                      <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                        <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                          onClick={() => {
                            setClassModal(false)
                            setAddNew(false)
                          }}
                        >X</div>
                        <div style={{ overflowY: "scroll" }}>
                          <table>
                            <thead>
                              <tr>
                                <td style={{ textAlign: "center", fontWeight: "bold", fontSize: 19 }}>Classes</td>
                              </tr>
                            </thead>
                            <tbody>
                              {Class.map((item, index) => (
                                <tr key={index} style={{ cursor: "pointer", textAlign: "center" }}
                                  onClick={() => {
                                    setClassModal(false)
                                    setClassSelectId(item.id)
                                    setClassSelect(item.title.rendered)
                                  }}
                                >
                                  <td style={{ textAlign: "center" }}>{item.title.rendered}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              }
              {SectionModal &&
                <section className="modal" >
                  <div className="row">
                    <div className="col-md-4 col-1"></div>
                    <div className="col-md-4 col-10 shadow bg-white">
                      <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                        <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                          onClick={() => {
                            setSectionModal(false)
                          }}
                        >X</div>
                        <div style={{ overflowY: "scroll" }}>
                          <table>
                            <thead>
                              <tr>
                                <td style={{ textAlign: "center", fontWeight: "bold", fontSize: 19 }}>Section</td>
                              </tr>
                            </thead>
                            <tbody>
                              {Section.filter(a => a.title.rendered.includes(ClassSelect)).map((item, index) => (
                                <tr key={index} style={{ cursor: "pointer", textAlign: "center" }}
                                  onClick={() => {
                                    setSectionModal(false)
                                    setSectionSelectID(item.id)
                                    setSectionSelect(item.title.rendered)
                                  }}
                                >
                                  <td style={{ textAlign: "center" }}>{item.title.rendered.replace(`-${ClassSelect}`, "")}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              }
            </div>
          </>
        }
        {choose == 1 &&
          <DataTable
            paginationPerPage={100}
            paginationRowsPerPageOptions={[10, 50, 100, 1000]}
            columns={columns}
            data={Projects1}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            pagination
            selectableRows
            onSelectedRowsChange={(r) => {
              console.log("asdasd", r)
              setProjects2(r.selectedRows)
            }}
          />
        }
      </div>

      {deleteUsersModal &&
        <section className="modal" >
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setDeleteUsersModal(false)
                  }}
                >X</div>
                Do you really want to Delete all the Materials?<br />
                <div className="row">
                  <div className="col-3 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setDeleteUsersModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-3 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        for (let i = 0; i < Projects2.length; i++) {
                          setReload(true)
                          setDeleteUsersModal(false)
                          AdsPUTApi({
                            status: "draft"
                          }, slugs.db_slug_chapters, Projects2[i].id)
                            .then((res) => {
                              if (i == Projects2.length - 1) {
                                // GetProject()
                                GetAllProjects({
                                  per_page: 10000,
                                  _fields: "id,title,slug,meta"
                                })
                              }
                            })
                        }
                      }
                      }
                    >
                      Delete all Materials
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
                  <DataTable
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[10, 50, 100, 1000]}
                    columns={columns}
                    data={Projects2}
                    defaultSortFieldId={1}
                    defaultSortAsc={false}
                    striped
                    // sortIcon={<SortIcon />}
                    pagination
                    selectableRows={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }

      {AddNew &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setAddNew(false)
                  }}
                >X</div>
                <div style={{ overflowY: "scroll" }}>
                  <table>
                    <tr>
                      <td className="col-4"><b>SN : </b></td>
                      <td className="col-7">
                        {Number(CategoryNewCache[CategoryNewIndex].id) + 1}
                      </td>
                    </tr>
                    <tr>
                      <td className="col-4"><b>Name : </b></td>
                      <td className="col-7">
                        <input
                          className="p-0 px-2 col-12"
                          value={CategoryNewCache[CategoryNewIndex].name}
                          onChange={(event) => {
                            var changeValue = {
                              ...CategoryNewCache[CategoryNewIndex],
                              "name": event.target.value
                            }
                            console.log(changeValue)
                            var AllChange = {
                              ...CategoryNewCache,
                              [CategoryNewIndex]: changeValue
                            }
                            console.log(AllChange)
                            setCategoryNewCache(AllChange)
                          }}
                          placeholder="Name"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
                <div style={{ padding: "10px" }}>
                  <div className="row">
                    <div className="col-1" />
                    <div className="col-5 center " >
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setAddNew(false)
                          setCategoryNewCache(CategoryNew)
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                    {/* {CategoryNewCache[CategoryNewIndex].name} */}
                    <div className="col-5 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          // setAddNew(false)
                          setCategoryNew(CategoryNewCache)
                          AdsPUTApi({
                            meta: {
                              "listing_value": JSON.stringify(CategoryNewCache)
                            }
                          }, slugs.db_slug_extra, 6447)
                            .then((res) => {
                              setAddNew(false)
                              setReload(true)
                              for (let i = 0; i < Class.length; i++) {
                                AdsPOSTApi({
                                  "title": `${CategoryNewCache[CategoryNewIndex].name}-${Class[i].title.rendered}`,
                                  "status": "publish",
                                  meta: {
                                    // "listing_value": JSON.stringify(CategoryNewCache)
                                    "batch_name": `${CategoryNewCache[CategoryNewIndex].name}-${Class[i].title.rendered}`,
                                    "chapters": "[]",
                                    "category_id": `${Class[i].id}`,
                                    "category_name": `${Class[i].title.rendered}`
                                  }
                                }, slugs.db_slug_batch)
                                  .finally(() => {
                                    if (i == Class.length - 1) {
                                      setReload(false)
                                    }
                                  })
                              }
                            })
                            .catch(err => {
                              console.log(err)
                            }).finally(() => {
                            })
                        }}
                      >
                        Done
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>
      }
    </div>
  )
}