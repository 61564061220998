import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth, AdsPOSTApi, NodePOSTApiAuth } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.meta.user_id,
    sortable: true,
    reorder: true,
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true
  },
  {
    id: 312,
    name: "Phone",
    selector: (row) => row.meta.user_phone_number,
    sortable: true,
    reorder: true
  },
  {
    id: 313,
    name: "Email",
    selector: (row) => row.meta.user_email,
    sortable: true,
    reorder: true
  },
  {
    id: 32,
    name: "role",
    selector: (row) => row.meta.user_role,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "View",
    cell: (row) => <Link to={`${slugs.student_details}${row.slug}`}>View</Link>,

  }
];

export default function AllStudents() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [ImportData, setImportData] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [SubscriberTotal, setSubscriberTotal] = React.useState(0)
  const [StudentTotal, setStudentTotal] = React.useState(0)
  const [UnverifiedTotal, setVerifiedTotal] = React.useState(0)
  const [AdminTotal, setAdminTotal] = React.useState(0)
  const [AllTotal, setAllTotal] = React.useState(0)
  const [serachName, setSearchName] = React.useState("")
  const [serachEmail, setSearchEmail] = React.useState("")
  const [serachPhone, setSearchPhone] = React.useState("")
  const [toStudentModel, setToStudentModal] = React.useState(false)
  const [classList, setClassList] = React.useState([])
  const [ChangeToStudentModal, setChangeToStudentModal] = React.useState(false)
  const [ChangeToStudentBatchModal, setChangeToStudentBatchModal] = React.useState(false)
  const [Projects2, setProjects2] = React.useState([])
  const [choose, setChoose] = React.useState(0)

  const [Class, setClass] = React.useState([])
  const [Section, setSection] = React.useState([])
  const [ClassModal, setClassModal] = React.useState(false)
  const [SectionModal, setSectionModal] = React.useState(false)
  const [ClassSelect, setClassSelect] = React.useState("")
  const [SectionSelect, setSectionSelect] = React.useState("")
  const [SectionSelectID, setSectionSelectID] = React.useState("")
  const [ClassSelectId, setClassSelectId] = React.useState("")

  const [batchList, setBatchList] = React.useState([])
  const [batchSelectListModal, setBatchSelectListModal] = React.useState(false)
  const [batchSelectList, setBatchSelectList] = React.useState([])
  const [batchCacheList, setBatchCacheList] = React.useState([])
  const [batchID, setBatchID] = React.useState("")
  const [batchPrice, setBatchPrice] = React.useState("")
  const [batchUpto, setBatchUpto] = React.useState("")
  const [batchName, setBatchName] = React.useState("")
  const [deleteUsersModal, setDeleteUsersModal] = React.useState(false)

  const [file, setFile] = React.useState();
  const fileReader = new FileReader();
  const fileRefSingle = React.createRef();
  const date = new Date();

  const GetTotal = (Role, setDataLength, all) => {
    setReload(true)
    setProjects1([])
    const allT = !all ? {
      "filter[meta_query][1][key]": "user_role",
      "filter[meta_query][1][value][0]": `${Role}`,
      "filter[meta_query][1][compare]": "=",
      per_page: 1
    } : { per_page: 1 }
    AdsGETApiAuth(allT, slugs.db_slug_user_data)
      .then((res) => {
        setDataLength(res.headers["x-wp-total"])
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_user_data)
      .then((res) => {
        setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAll()
  }, [])


  const GetAll = () => {
    GetAllProjects({ per_page: 4999, _fields: "id,slug,meta,title" })
    GetTotal("subscriber", setSubscriberTotal, false)
    GetTotal("student", setStudentTotal, false)
    GetTotal("author", setAdminTotal, false)
    GetTotal("all", setAllTotal, true)
    GetAllClass()
    GetAllSection()
  }

  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);
    e.preventDefault();
    const file = e.target.files[0]
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        const jsonData = convertCSVToJson(csvOutput);

        // console.log(jsonData)
        var newData = []
        for (let i = 0; i < jsonData.length; i++) {
          const jsonDatai = jsonData[i]
          // console.log(jsonDatai)
          // const searchEmail = Projects1.filter(a => a.meta.user_name == jsonDatai["Login Name"])
          const searchEmail = Projects1.filter(a => a.meta.user_email == jsonDatai["Email"])
          // console.log(jsonDatai, "asddasd", searchEmail.length)
          const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
          function validateEmail(email) {
            return emailRegex.test(email);
          }
          const isValidEmail = validateEmail(jsonDatai["Email"]);
          var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          var passwordLength = 12;
          var password = "";
          for (let l = 0; l <= passwordLength; l++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
          }
          // console.log(isValidEmail,jsonDatai["Email"],jsonDatai)
          if (isValidEmail)
            if (searchEmail.length == 0)
              newData.push({
                "title": { rendered: jsonDatai["Name"], },
                "status": "publish",
                "meta": {
                  "user_role": "student",
                  "user_name": jsonDatai["Email"],
                  "user_password": jsonDatai["Password"] == "" ? password : jsonDatai["Password"],
                  "user_email": jsonDatai["Email"],
                }
              })
          // console.log(newData)
          setImportData(newData)
        }
      };
      fileReader.readAsText(file);
    };
  }

  const convertCSVToJson = (csvData) => {
    const lines = csvData.split("\n");
    const headers = lines[0].replace("\r", "").split(",");
    const result = [];
    console.log("asdasd", headers, lines[0])
    for (let i = 1; i < lines.length; i++) {
      var obj = {};
      const currentLine = lines[i].replace("\r", "").split(",");
      // const currentLine = lines[i].split(",");
      console.log(currentLine, lines[i])
      for (let j = 0; j < headers.length; j++) {
        // obj[headers[j].trim()] = currentLine[j].trim();
        console.log(currentLine[j])
        var key = headers[j]
        obj = {
          ...obj,
          [key]: currentLine[j]
        }
      }
      console.log(obj)

      result.push(obj);
    }
    // setImportData(result)
    return result;
  };

  const MappingExam = ({ item }) => {
    const EnrollmentSearch = Object.values(batchCacheList).filter(a => a.course_id == item.id)
    const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
    const clickFunction = () => {
      if (!CheckON)
        setCheckON(true)
      else
        setCheckON(false)
      if (batchCacheList.filter(a => a.course_id == item.id).length == 0) {
        batchCacheList.push({
          course_id: item.id,
          name: item.title.rendered,
          slug: item.slug
        })
        // setBatchSelectListModal(false)
        // BatchExamIDCache.push(item.id)
      }
    }
    return (
      <>
        {/* <td onClick={() => { clickFunction() }}>
          {CheckON ?
            <i className="fa fa-check-square-o" />
            :
            <i className="fa fa-square-o" />
          }
        </td> */}

        <td onClick={() => { clickFunction() }}>
          {/* <i className="fa fa-square-o" /> */}
          {CheckON ?
            <i className="fa fa-check-square-o" />
            :
            <i className="fa fa-square-o" />
          }
        </td>
        <td>{item.title.rendered.replace(`-${ClassSelect}`, "")}</td>
        <td style={{ cursor: "pointer" }}
        >Choose</td>

        {/* <td onClick={() => { clickFunction() }}>{item.id}</td>
        <td onClick={() => { clickFunction() }}>{item.title.rendered}</td>
        <td onClick={() => { clickFunction() }}>{item.meta.category_name}</td> */}
        {/* <td style={{ cursor: "pointer" }} onClick={() => { clickFunction() }}
        
        >Choose</td> */}
      </>
    )
  }



  const columns2 = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true
    },
    {
      id: 3123,
      name: "Class",
      selector: (row) => row.meta.category_name,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: "View",
      cell: (row) =>
        <div style={{ cursor: "pointer" }}
          onClick={() => {
            var studentList = [];
            for (let i = 0; i < CustomersList.length; i++) {
              if (CustomersList[i].meta.verified_course_list !== "") {
                var courseList = Object.values(JSON.parse(CustomersList[i].meta.verified_course_list))
                if (courseList.filter(a => a.course_id == row.id).length !== 0) {
                  studentList.push(CustomersList[i])
                }
              }
            }
            setProjects1(studentList)
            setChoose(0)
          }}
        >
          Choose
        </div>
      ,

    }
  ];

  const GetAllClass = () => {
    setReload(true)
    AdsGETApiAuth({
      _fields: "id,title,slug,meta",
      per_page: 1000
    }, `${slugs.db_slug_category}`)
      .then((res) => {
        setClass(res.data)
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
      })
  }
  const GetAllSection = () => {
    setReload(true)
    AdsGETApiAuth({
      _fields: "id,title,slug,meta",
      per_page: 1000
    }, `${slugs.db_slug_batch}`)
      .then((res) => {
        setSection(res.data)
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
      })
  }

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Students</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Students
        </div>
      </div>
      <div style={{ display: "inline", cursor: "pointer" }}>
        <div style={{ display: "inline-block", margin: "0px 5px" }}>

          <a href={slugs.new_student}>
            <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
              onClick={() => {

              }}
            >Add New Student</div>
          </a>
        </div>
        <div style={{ display: "inline-block", margin: "0px 5px" }}
          onClick={() => {
            setImportData([])
            setToStudentModal(true)
          }}
        >
          Add Multiple |
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div style={{ display: "inline", cursor: "pointer" }}>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(0)
                setProjects1(CustomersList)
              }}
            >
              All ({AllTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(0)
                setProjects1(CustomersList.filter(a => a.meta.user_role == "subscriber"))
              }}
            >
              Subscribers ({SubscriberTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(0)
                setProjects1(CustomersList.filter(a => a.meta.user_role == "student"))
              }}
            >
              Students ({StudentTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(0)
                setProjects1(CustomersList.filter(a => a.meta.user_role == "teacher"))
              }}
            >
              Teacher ({CustomersList.filter(a => a.meta.user_role == "teacher").length}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(0)
                setProjects1(CustomersList.filter(a => a.meta.user_role == "author"))
              }}
            >
              Admin ({AdminTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(1)
              }}
            >
              Filter
            </div>
          </div>
        </div>
        <div className="col-md-6 row">
          <div className="col-4" />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachName} placeholder="Name"
            onChange={(event) => {
              setSearchName(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.user_name.toLowerCase().includes(event.target.value.toLowerCase())))
            }}
          />
          <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachEmail} placeholder="Email"
            onChange={(event) => {
              setSearchEmail(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.user_email.includes(event.target.value)))
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachPhone} placeholder="Phone"
            onChange={(event) => {
              setSearchPhone(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.user_phone_number.includes(event.target.value)))
            }}
          />
        </div>
      </div>

      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        {choose == 0 &&
          <DataTable
            paginationPerPage={100}
            paginationRowsPerPageOptions={[10, 50, 100, 1000]}
            columns={columns}
            data={Projects1}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
          />
        }
        {/* {choose == 1 &&
          <DataTable
            columns={columns2}
            data={batchList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            paginationPerPage={100}
            paginationRowsPerPageOptions={[10, 50, 100, 1000]}
            pagination
            selectableRows
          />
        } */}
        {choose == 1 &&
          <>
            <div style={{ padding: 20, backgroundColor: "#fff" }}>
              <div>
                Class : {ClassSelect}  <span style={{ cursor: "pointer", border: "1px solid black", borderRadius: 5, padding: "10px" }}
                  onClick={() => {
                    setClassModal(true)
                    setSectionSelect("")
                  }}
                >Choose</span>
              </div>
              <br />
              {ClassSelect !== "" &&
                <>
                  <div>
                    Section : {SectionSelect.replace(`-${ClassSelect}`, "")}
                    <span style={{ cursor: "pointer", border: "1px solid black", borderRadius: 5, padding: "10px" }}
                      onClick={() => {
                        setSectionModal(true)
                      }}
                    >Choose</span>
                  </div>
                  <br />
                  <div>
                    <span style={{ cursor: "pointer", border: "1px solid black", borderRadius: 5, padding: 10 }}
                      onClick={() => {
                        var studentList = [];
                        if (SectionSelect == "")
                          for (let i = 0; i < CustomersList.length; i++) {
                            if (CustomersList[i].meta.verified_course_list !== "") {
                              var courseList = Object.values(JSON.parse(CustomersList[i].meta.verified_course_list))
                              console.log(courseList, CustomersList[i].title.rendered, ClassSelect)
                              if (courseList.length !== 0)
                                if (courseList.filter(a => a.name.includes(`${ClassSelect}`)).length !== 0) {
                                  studentList.push(CustomersList[i])
                                }
                            }
                          }
                        else
                          for (let i = 0; i < CustomersList.length; i++) {
                            if (CustomersList[i].meta.verified_course_list !== "") {
                              var courseList = Object.values(JSON.parse(CustomersList[i].meta.verified_course_list))
                              console.log(courseList, CustomersList[i].title.rendered)
                              if (courseList.filter(a => a.name == `${SectionSelect}`).length !== 0) {
                                studentList.push(CustomersList[i])
                              }
                            }
                          }
                        setProjects1(studentList)
                        setChoose(0)
                        // if (SectionSelect == "")
                        //   setProjects1(CustomersList.filter(a => a.meta.batches.includes(`${ClassSelect}`)))
                        // else
                        //   setProjects1(CustomersList.filter(a => a.meta.batches.includes(`${SectionSelect}`)))
                        // setChoose(1)
                      }
                      }
                    >View Students</span>
                  </div>
                </>
              }
              {ClassModal &&
                <section className="modal" >
                  <div className="row">
                    <div className="col-md-4 col-1"></div>
                    <div className="col-md-4 col-10 shadow bg-white">
                      <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                        <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                          onClick={() => {
                            setClassModal(false)
                          }}
                        >X</div>
                        <div style={{ overflowY: "scroll" }}>
                          <table>
                            <thead>
                              <tr>
                                <td style={{ textAlign: "center", fontWeight: "bold", fontSize: 19 }}>Classes</td>
                              </tr>
                            </thead>
                            <tbody>
                              {Class.map((item, index) => (
                                <tr key={index} style={{ cursor: "pointer", textAlign: "center" }}
                                  onClick={() => {
                                    setClassModal(false)
                                    setClassSelectId(item.id)
                                    setClassSelect(item.title.rendered)
                                  }}
                                >
                                  <td style={{ textAlign: "center" }}>{item.title.rendered}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              }
              {SectionModal &&
                <section className="modal" >
                  <div className="row">
                    <div className="col-md-4 col-1"></div>
                    <div className="col-md-4 col-10 shadow bg-white">
                      <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                        <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                          onClick={() => {
                            setSectionModal(false)
                          }}
                        >X</div>
                        <div style={{ overflowY: "scroll" }}>
                          <table>
                            <thead>
                              <tr>
                                <td style={{ textAlign: "center", fontWeight: "bold", fontSize: 19 }}>Section</td>
                              </tr>
                            </thead>
                            <tbody>
                              {Section.filter(a => a.title.rendered.includes(ClassSelect)).map((item, index) => (
                                <tr key={index} style={{ cursor: "pointer", textAlign: "center" }}
                                  onClick={() => {
                                    setSectionModal(false)
                                    setSectionSelectID(item.id)
                                    setSectionSelect(item.title.rendered)
                                  }}
                                >
                                  <td style={{ textAlign: "center" }}>{item.title.rendered.replace(`-${ClassSelect}`, "")}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              }
            </div>
          </>
        }
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {toStudentModel &&
        <section className="modal" >
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-md-2 col-1"></div>
            <div className="col-md-8 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setToStudentModal(false)
                    setImportData([])

                  }}
                >X</div>
                <div style={{ display: "none" }} >
                  <input ref={fileRefSingle}
                    type={"file"}
                    id={"csvFileInput"}
                    accept={".csv"}
                    onChange={handleOnChange}
                  />
                </div>
                <div style={{ display: "inline", cursor: "pointer" }}>
                  {/* <div style={{ display: "inline-block" }}>
                    <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
                      onClick={() => {
                        fileRefSingle.current.click()
                      }}
                    >Import CSV</div>
                  </div> */}
                  <div style={{ display: "inline-block" }}>
                    <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
                      onClick={() => {
                        setBatchSelectListModal(true)
                      }}
                    >Select Class</div>
                  </div>
                </div>

                {batchSelectList.map((item, index) => (
                  <span key={index}>{item.name} , </span>
                ))}

                {batchSelectListModal &&
                  <section className="modal" >
                    <div className="row" style={{ overflow: "hidden", marginTop: "30px" }}>
                      <div className="col-md-3 col-1"></div>
                      <div className="col-md-6 col-10  shadow bg-white">
                        <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                          <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                            onClick={() => {
                              setBatchSelectListModal(false)
                              setImportData([])

                            }}
                          >X</div>
                          Class List
                          <div style={{ height: "50vh", overflowY: "scroll", }}>
                            {/* <table style={{}}>
                              <thead>
                                <tr>
                                  <td>SN</td>
                                  <td>Batch</td>
                                  <td>Class ID</td>
                                  <td>Category</td>

                                </tr>
                                {batchList.map((item, index) => (
                                  <tr key={index}>
                                    <MappingExam item={item} />
                                  </tr>
                                ))}
                              </thead>
                            </table> */}
                            <div style={{ height: "400px", overflowY: "scroll" }}>
                              <div style={{ padding: 20, backgroundColor: "#fff" }}>
                                <div>
                                  Class : {ClassSelect}  <span style={{ cursor: "pointer", border: "1px solid black", borderRadius: 5, padding: "10px" }}
                                    onClick={() => {
                                      setClassModal(true)
                                      setSectionSelect("")
                                    }}
                                  >Choose</span>
                                </div>
                                <br />

                                {ClassModal &&
                                  <section className="modal" >
                                    <div className="row">
                                      <div className="col-md-4 col-1"></div>
                                      <div className="col-md-4 col-10 shadow bg-white">
                                        <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                                          <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                                            onClick={() => {
                                              setClassModal(false)
                                            }}
                                          >X</div>
                                          <div style={{ overflowY: "scroll" }}>
                                            <table>
                                              <thead>
                                                <tr>
                                                  <td style={{ textAlign: "center", fontWeight: "bold", fontSize: 19 }}>Classes</td>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {Class.map((item, index) => (
                                                  <tr key={index} style={{ cursor: "pointer", textAlign: "center" }}
                                                    onClick={() => {
                                                      setClassModal(false)
                                                      setClassSelect(item.title.rendered)
                                                    }}
                                                  >
                                                    <td style={{ textAlign: "center" }}>{item.title.rendered}</td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                }
                              </div>
                              {ClassSelect !== "" &&
                                <div>
                                  Section :
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td></td>
                                        <td>Section Name</td>
                                        <td>Choose</td>
                                      </tr>

                                      {Section.filter(a => a.title.rendered.includes(ClassSelect)).map((item, index) => 
                                      {
                                        if (Object.values(batchCacheList).filter(a => a.course_id == item.id).length == 0)
                                          return (
                                            // <tr key={index}
                                            //   onClick={() => {
                                            //     batchCacheList.push({
                                            //       course_id: item.id,
                                            //       name: item.title.rendered,
                                            //       slug: item.slug
                                            //     })
                                            //     setBatchSelectListModal(false)
                                            //   }}
                                            // >
                                            //   <td>
                                            //     <i className="fa fa-square-o" />
                                            //   </td>
                                            //   <td>{item.title.rendered.replace(`-${ClassSelect}`, "")}</td>
                                            //   <td style={{ cursor: "pointer" }}
                                            //   >Choose</td>
                                            // </tr>
                                            <tr key={index}>
                                              <MappingExam item={item} />
                                            </tr>
                                          )
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              }
                            </div>

                          </div>
                          <div style={{ padding: "10px" }}>
                            <div className="row">
                              <div className="col-1" />
                              <div className="col-5 center " >
                                <div className="sign-in-button-4" style={{ width: "80%" }}
                                  onClick={() => {
                                    setBatchCacheList(batchSelectList)
                                    // setBatchExamIDCache(BatchExamID)
                                    setBatchSelectListModal(false)
                                  }}
                                >
                                  Cancel
                                </div>
                              </div>
                              <div className="col-5 center">
                                <div className="sign-in-button-4" style={{ width: "80%" }}
                                  onClick={() => {
                                    setBatchSelectList(batchCacheList)
                                    // setBatchExamID(BatchExamIDCache)
                                    setBatchSelectListModal(false)
                                  }}
                                >
                                  Done
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                }

                <div style={{ maxHeight: "50vh", overflow: "scroll", backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
                  <table style={{ maxHeight: "50vh" }}>
                    <thead>
                      <tr>
                        <td>SN</td>
                        <td>Name</td>
                        <td>Email</td>
                        <td>Password</td>
                        <td>Phone Number</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.values(ImportData).map((item, index) => (
                        <tr key={index}>
                          <TableRow row={item} index={index}
                            ImportData={ImportData} setImportData={setImportData} />
                        </tr>
                      ))}

                      <tr>
                        <td colSpan={6} className="center" style={{ cursor: "pointer" }}
                          onClick={() => {
                            setImportData(
                              {
                                ...ImportData,
                                [Object.values(ImportData).length]: {
                                  "title": { rendered: "", },
                                  "meta": {
                                    "user_role": "student",
                                    "user_name": "",
                                    "user_password": "",
                                    "user_phone_number": "",
                                    "user_email": "",
                                    "verified_course_list": JSON.stringify(batchCacheList)
                                  }
                                }
                              }
                            )
                          }}
                        >
                          Add New
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row center">
                  <div className="col-3 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setToStudentModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-3 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        // NodePOSTApiAuth({
                        //   studentList: JSON.stringify(ImportData),
                        //   batchList: batchSelectList,
                        // }, "register-students")
                        //   .then(res => {
                        //     console.log(res.data)
                        //     alert(`${res.data.data} students added`)
                        //     setReload(false)
                        //     GetAllProjects({ per_page: 4999 })
                        //     // GetAll()
                        //     setToStudentModal(false)
                        //   })
                        //   .catch(err => {
                        //     console.log(err)
                        //   })
                        const Projects = Object.values(ImportData)
                        for (let i = 0; i < Projects.length; i++) {
                          const item = {
                            title: Projects[i].title.rendered,
                            "status": "publish",
                            meta: Projects[i].meta,
                          }
                          var email = item.meta.user_email
                          const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
                          function validateEmail(email) {
                            return emailRegex.test(email);
                          }
                          var isValidEmail = validateEmail(item.meta.user_email);
                          var chars = "0123456789abcdefghijklmnopqrstuvwxyz()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                          var passwordLength = 12;
                          var password = "";
                          for (let l = 0; l <= passwordLength; l++) {
                            var randomNumber = Math.floor(Math.random() * chars.length);
                            password += chars.substring(randomNumber, randomNumber + 1);
                          }
                          var passwordConfirmed;
                          if (item.meta.user_password != "") {
                            passwordConfirmed = item.meta.user_password
                          }
                          else
                            isValidEmail = false

                          if (isValidEmail) {
                            const dataWithPassword = {
                              "username": item.meta.user_name,
                              "email": item.meta.user_email,
                              "password": passwordConfirmed,
                              "roles": item.meta.user_role
                            }
                            console.log(item)
                            setReload(true)
                            AdsPOSTApi(dataWithPassword, "users")
                              .then(res => {
                                const item = {
                                  title: Projects[i].title.rendered,
                                  "status": "publish",
                                  meta: {
                                    ...Projects[i].meta,
                                    "user_id": `${res.data.id}`
                                  }
                                }
                                console.log(item)
                                AdsPOSTApi(item, slugs.db_slug_user_data)
                                  .then((res) => {
                                    console.log(item)
                                  }).catch(err => {
                                    console.log(err)
                                  })

                              })
                              .catch(err => {
                                console.log(err)
                              })
                              .finally(() => {
                                if (i == Projects.length - 1) {
                                  alert(`${Projects.length} students added`)
                                  setReload(false)
                                  GetAll()
                                  setToStudentModal(false)
                                }
                              })
                          }
                          else
                            if (i == Projects.length - 1) {
                              alert(`${Projects.length} students added`)
                              setReload(false)
                              GetAll()
                              setToStudentModal(false)
                            }
                        }
                      }}
                    >
                      Add All Student
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {Reload &&
            <div className="modal" style={{ padding: "50vw" }}>
              <div className="dot-pulse"></div>
            </div>
          }
        </section>
      }
    </div >
  )
}



const TableRow = ({ row, index, ImportData, setImportData }) => {
  const [name, setName] = React.useState(row.title.rendered)
  const [LoginName, setLoginName] = React.useState(row.meta.user_name)
  const [email, setEmail] = React.useState(row.meta.user_email)
  const [PassWord, setPassword] = React.useState(row.meta.user_password)
  const [PhoneNumber, setPhoneNumber] = React.useState(row.meta.user_phone_number)
  const [Role, setRole] = React.useState(row.meta.user_role)
  return (
    <>
      <td>{index + 1}</td>
      <td>
        <input className="input-common-2"
          value={name} placeholder="User Name"
          onChange={(event) => {
            setName(event.target.value)
            console.log(JSON.stringify(ImportData[index]))

            const ImportData2 = {
              ...ImportData,
              [index]: {
                title: { rendered: event.target.value },
                meta: { ...ImportData[index].meta, user_name: event.target.value }
              }
            }
            setImportData(ImportData2)
          }}
        />
      </td>
      <td>
        <input className="input-common-2"
          value={email} placeholder="Email"
          onChange={(event) => {
            setEmail(event.target.value)
            const ImportData2 = {
              ...ImportData,
              [index]: {
                title: ImportData[index].title,
                meta: { ...ImportData[index].meta, user_email: event.target.value }
              }
            }
            setImportData(ImportData2)
          }}
        />
      </td>

      <td>
        <div style={{ display: "inline" }}>
          <div style={{ display: "inline-block" }}>

            <input className="input-common-2 col-9"
              value={PassWord} placeholder="Password"
              onChange={(event) => {
                setPassword(event.target.value)
                const ImportData2 = {
                  ...ImportData,
                  [index]: {
                    title: ImportData[index].title,
                    meta: { ...ImportData[index].meta, user_password: event.target.value }
                  }
                }
                setImportData(ImportData2)
              }}
            />
            <div style={{ display: "inline-block", cursor: "pointer" }}
              onClick={() => {
                var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
                var passwordLength = 12;
                var password = "";
                for (let l = 0; l <= passwordLength; l++) {
                  var randomNumber = Math.floor(Math.random() * chars.length);
                  password += chars.substring(randomNumber, randomNumber + 1);
                }
                setPassword(password)
                const ImportData2 = {
                  ...ImportData,
                  [index]: {
                    title: ImportData[index].title,
                    meta: { ...ImportData[index].meta, user_password: password }
                  }
                }
                setImportData(ImportData2)
              }}
            >
              &nbsp;
              <img src="https://api.gradeplusnepal.com/wp-content/uploads/2024/05/Screenshot-2024-05-22-at-12.41.09.jpeg"
                style={{ width: "20px", height: "20px" }}
              />
              <i className="fa fa-reload" />
              {/* Generate */}
            </div>
          </div>
        </div>
      </td>
      <td>
        <input className="input-common-2"
          value={PhoneNumber} placeholder="Phone Number"
          onChange={(event) => {
            setPhoneNumber(event.target.value)
            const ImportData2 = {
              ...ImportData,
              [index]: {
                title: ImportData[index].title,
                meta: { ...ImportData[index].meta, user_phone_number: event.target.value }
              }
            }
            setImportData(ImportData2)
          }}
        />
      </td>
      {/* <td>
        <CustomButton setData={setRole} Data={Role} DataValue={"subscriber"}
          index={index} ImportData={ImportData} setImportData={setImportData} />
        <CustomButton setData={setRole} Data={Role} DataValue={"student"}
          index={index} ImportData={ImportData} setImportData={setImportData} />
      </td> */}
      {/* <td onClick={() => {
      }}>
        Done
      </td> */}
    </>
  )
}