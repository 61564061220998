import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import PicturesUpload from "./MediaArrayUpload";
import { AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { CustomButton } from "../../../CustomButtom";
import ImageUpload from "../../../ImageUpload";

export default function ImageUploadMain(props) {
  const { FetchData } = props;

  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)


  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [Status, setStatus] = React.useState(FetchData.status)
  const [reload, setReload] = React.useState(false)
  const [ImageArray, setImageArray] = React.useState(FetchData.meta.picture_url_list)
  const [ImageArrayReload, setImageArrayReload] = React.useState(true)
  const [whatsappGroup, setWhatsappGroup] = React.useState(FetchData.meta.whatsapp_group)
  const [LiveLink, setLiveLink] = React.useState(FetchData.meta.live_link)
  const [videoLink, setvideoLink] = React.useState(FetchData.meta.video_link)
  const [ImageArrayVerified, setImageArrayVerified] = React.useState(FetchData.meta.picture_verified_list)
  const [ImageArrayVerifiedReload, setImageArrayVerifiedReload] = React.useState(true)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": Title,
      "status": Status,
      "meta": {
        "picture_url_list": ImageArray,
        "picture_verified_list": ImageArrayVerified,
        "whatsapp_group": whatsappGroup,
        "live_link": LiveLink,
        "video_link": videoLink
      }
    }
    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_images)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Images Added Successfully")
          history.push(`${slugs.imaegs_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_images, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  // useEffect(() => {
  //   fetchSliders()
  // }, [])
  // const fetchSliders = () => {
  //   setImageArrayReload(false)
  //   AdsGETApi({ _fields: "meta" }, "image_home")
  //     .then((res) => {
  //       if (res.data.length !== 0) {
  //         setvideoLink(res.data[0].meta.video_link)
  //         setLiveLink(res.data[0].meta.live_link)
  //         setWhatsappGroup(res.data[0].meta.whatsapp_group)
  //         if (res.data[0].meta.picture_url_list.length !== 0)
  //           setImageArray(JSON.parse(res.data[0].meta.picture_url_list))
  //         // if (res.data[0].meta.picture_verified_list.length !== 0)
  //         setImageArrayVerified(JSON.parse(res.data[0].meta.picture_verified_list))

  //       }
  //       setImageArrayReload(true)
  //       setImageArrayVerifiedReload(true)
  //     })
  // }

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-sm-2 col-1"></div>
          <div className="col-sm-8 col-10 form-view mb-3">
            <h4><center>Details</center></h4>
            <table>
              <tbody>
                <tr>
                  <td className="col-3">Title</td>
                  <td className="col-6">
                    <input className="input-common"
                      value={Title} placeholder="Title"
                      onChange={(event) => { setTitle(event.target.value) }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-3">Whatsapp Link</td>
                  <td className="col-6">
                    <input className="input-common"
                      value={whatsappGroup} placeholder="Whatsapp Group Link"
                      onChange={(event) => { setWhatsappGroup(event.target.value) }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-3">Live Link</td>
                  <td className="col-6">
                    <input className="input-common"
                      value={LiveLink} placeholder="Live Link"
                      onChange={(event) => { setLiveLink(event.target.value) }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-3">Video Link</td>
                  <td className="col-6">
                    <input className="input-common"
                      value={videoLink} placeholder="Video Link"
                      onChange={(event) => { setvideoLink(event.target.value) }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-3">Status</td>
                  <td className="col-6">
                    <CustomButton setData={setStatus} Data={Status} DataValue={"publish"} />
                    <CustomButton setData={setStatus} Data={Status} DataValue={"draft"} />
                  </td>
                </tr>
                <tr>
                  <td className="col-3">Image</td>
                  <td className="col-6">
                    {/* <PicturesUpload
                      ImageArray={ImageArray}
                      setImageArray={setImageArray}
                      ImageArrayReload={ImageArrayReload}
                      setImageArrayReload={setImageArrayReload}
                    /> */}
                    <ImageUpload
                      ImageUrl={ImageArray}
                      setImageUrl={setImageArray}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-3">Image For Verified</td>
                  <td className="col-6">
                    <ImageUpload
                      ImageUrl={ImageArrayVerified}
                      setImageUrl={setImageArrayVerified}
                    />
                    {/* <PicturesUpload
                      ImageArray={ImageArrayVerified}
                      setImageArray={setImageArrayVerified}
                      ImageArrayReload={ImageArrayVerifiedReload}
                      setImageArrayReload={setImageArrayVerifiedReload}
                    /> */}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="row center m-2">
              <div className=" col-3" style={{ padding: "0 10px" }}>
                <div className="sign-in-button-4"
                  onClick={() => {
                    EditInfo()
                  }}
                >
                  Update Images
                </div>
              </div>
            </div>
          </div>
        </div>

        {reload &&
          <div className="modal" style={{ padding: "50vw" }}>
            <div className="dot-pulse"></div>
          </div>
        }
      </div>
    </div >
  )

}