import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import { CustomButtonUnSelect } from "../../../CustomButtomUnselect";
import FileUpload from "../../../FileUpload";
import ImageUpload from "../../../ImageUpload";

export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [CategoryCode, setCategoryCode] = React.useState(FetchData.meta.description)

  const [Time, setTime] = React.useState("")
  const [Date, setDate] = React.useState("")
  const [Status, setStatus] = React.useState("")

  const [FeaturedImage, setFeaturedImage] = React.useState(FetchData.meta.featured_image)
  const [NoticeFor, setNoticeFor] = React.useState(FetchData.meta.featured_image)

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [Free, setFree] = React.useState(FetchData.meta.notice_for_all)

  const type_array = {
    "korean": "Korean",
    "nasu": "nasu",
    "sakhaadhikrit": "sakhaadhikrit",
  }
  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": Title,
      "status": "publish",
      "meta": {
        "featured_image": FeaturedImage,
        "description": `
${CategoryCode}
${JSON.stringify(VerifiedCourse)}

`,
        "notice_for": NoticeFor,
        "notice_for_list": JSON.stringify(VerifiedCourse),
        "notice_for_all": Free
        // "notice_for_name": ""
      }
    }

    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_notice)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Notice Added Successfully")
          history.push(`${slugs.notice_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_notice, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }

  const [AddNewCourse, setAddNewCourse] = React.useState(false)
  const [serachCourseName, setSearchCourseName] = React.useState("")
  const [CourseArray, setCourseArray] = React.useState([])
  const [CourseArray1, setCourseArray1] = React.useState([])
  const [VerifiedCourse, setVerifiedCourse] = React.useState(
    FetchData.meta.notice_for_list == "" ? [] :
      JSON.parse(FetchData.meta.notice_for_list))
  const [VerifiedCourseCache, setVerifiedCourseCache] = React.useState(
    FetchData.meta.notice_for_list == "" ? [] :
      JSON.parse(FetchData.meta.notice_for_list))
  const [CourseDeleteIndex, setCourseIndex] = React.useState("")
  const [CourseDeleteModal, setCourseDeleteModal] = React.useState(false)

  const FetchCourseList = () => {
    AdsGETApiAuth(
      { per_page: 300, _fields: "id,alug,title,meta.featured_image,meta.exam_type" },
      slugs.db_slug_batch
    )
      .then((res) => {
        setCourseArray1(res.data)
        setCourseArray(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  useEffect(() => {
    // BatchList()
    FetchCourseList()
  }, [])

  const MappingCourse = ({ item }) => {
    const EnrollmentSearch = Object.values(VerifiedCourseCache).filter(a => a.course_id == item.id)
    const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
    const clickFunction = () => {
      setCheckON(true)
      if (VerifiedCourseCache.filter(a => a.course_id == item.id).length == 0) {
        VerifiedCourseCache.push({
          course_id: item.id,
          name: item.title.rendered,
          slug: item.slug
        })
      }
    }
    return (
      <>
        <td onClick={() => { clickFunction() }}>
          {CheckON ?
            <i className="fa fa-check-square-o" />
            :
            <i className="fa fa-square-o" />
          }
        </td>
        <td>{item.id}</td>
        <td>{item.title.rendered}</td>
        <td style={{ cursor: "pointer" }} onClick={() => { clickFunction() }}>Choose</td>
      </>
    )
  }


  return (
    <div>



      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Notice Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Notice Title</td>
                <td className="col-6">
                  <input className="input-common" style={{ width: "100%" }}
                    value={Title} placeholder="Title"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Content</td>
                <td className="col-6">
                  <textarea rows={5} className="input-common" style={{ width: "100%" }}
                    value={
                      `${CategoryCode}`
                    } placeholder="Content"
                    onChange={(event) => { setCategoryCode(event.target.value) }}
                  />
                 
                </td>
              </tr>
              <tr>
                <td className="col-3">Time</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Time} placeholder="Time"
                    onChange={(event) => { setTime(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Date</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Date} placeholder="Date"
                    onChange={(event) => { setDate(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Notice</td>
                <td className="col-6">
                   
          
मिति {Date}<br/>
{CategoryCode}<br/>
<br/>
{VerifiedCourse.map((item,index)=>(`${item.name} `))} : 
{Time} बजे बेलुका
                </td>
              </tr>
              <tr>
                <td className="col-3">Notice For All</td>
                <td className="col-6">
                  <CustomButtonUnSelect setData={setFree} Data={Free} DataValue={"All"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Notice Image</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={FeaturedImage}
                    setImageUrl={setFeaturedImage}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Notice
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Notice for Section List</center></h4>
          <table>
            <tbody>
              <tr>
                <td>Section ID</td>
                <td>Section Name</td>
              </tr>
              {VerifiedCourse.map((item, index) => (
                <tr key={index}>
                  <td>{item.course_id}</td>
                  <td>{item.name}</td>
                  <td style={{ cursor: "pointer" }} onClick={() => {
                    setCourseIndex(index)
                    setCourseDeleteModal(true)
                  }}>Delete</td>
                </tr>
              ))}
              <tr>
                <td style={{ cursor: "pointer" }} onClick={() => {
                  setAddNewCourse(true)
                }}>Add New</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {CourseDeleteModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setCourseDeleteModal(false)
                  }}
                >X</div>
                Do you really want to delete Section for the user?
                <table>
                  <tbody>
                    <tr>
                      <td>Section Name : </td>
                      <td>{VerifiedCourse[CourseDeleteIndex].name}</td>
                    </tr>
                    <tr>
                      <td>Section ID : </td>
                      <td>{VerifiedCourse[CourseDeleteIndex].course_id}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row" >
                  <div className="col-1 center " />
                  <div className="col-5 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setCourseDeleteModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-5 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        VerifiedCourse.splice(CourseDeleteIndex, 1)
                        // BatchExamID.splice(BatchExamDeleteIndex, 1)
                        setCourseDeleteModal(false)
                      }}
                    >
                      OK
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }

      {AddNewCourse &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setAddNewCourse(false)
                  }}
                >X</div>
                <div className="row" style={{ width: "100%", justifyContent: "space-evenly", cursor: "pointer", border: "1px solid #999" }} >
                  <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                    value={serachCourseName} placeholder="Name"
                    onChange={(event) => {
                      setSearchCourseName(event.target.value)
                      setCourseArray(CourseArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                    }}
                  />
                </div>

                <div style={{ height: "400px", overflowY: "scroll" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>Course ID</td>
                        <td>Course Name</td>
                        <td>Choose</td>
                      </tr>
                      {CourseArray.map((item, index) => (
                        <tr key={index}>
                          <MappingCourse item={item} />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={{ padding: "10px" }}>
                  <div className="row">
                    <div className="col-1" />
                    <div className="col-5 center " >
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setVerifiedCourseCache(VerifiedCourse)
                          // setBatchExamIDCache(BatchExamID)
                          setAddNewCourse(false)
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                    <div className="col-5 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setVerifiedCourse(VerifiedCourseCache)
                          // setBatchExamID(BatchExamIDCache)
                          setAddNewCourse(false)
                        }}
                      >
                        Done
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>
      }
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div>
  )

}

