import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import Database from '../../../../Constant/database.json'
import ImageUpload from "../../../ImageUpload";
import ExamQuestionModal from "./ExamQuestionModal";
import { CustomButtonUnSelect } from "../../../CustomButtomUnselect";
import ReactToPrint, { useReactToPrint } from 'react-to-print';

const QuestionBlock = [
  "question_number",
  "question",
  "question_description",
  "question_description_type",
  "question_media",
  "question_media_type",
  "option_1",
  "option_2",
  "option_3",
  "option_4",
  "answer_description",
  "correct_answer",
  "answer_media_type",
]

export default function FormComponent(props) {
  const { FetchData, db_slug, webSlug } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [Time, setTime] = React.useState(FetchData.meta.exam_time)
  const [Details, setDetails] = React.useState(FetchData.meta.exam_details)
  const [Available, setAvaliable] = React.useState(FetchData.meta.available_unavailable)
  const [FeaturedImage, setFeaturedImage] = React.useState(FetchData.meta.featured_image)
  const [ExamType, setExamType] = React.useState(FetchData.meta.exam_type)
  const [CopyTo, setCopyTo] = React.useState("")
  const [AudioSingle, setAudioSingle] = React.useState(FetchData.meta.media_non_repeat)
  const [ExamPrice, setExamPrice] = React.useState(FetchData.meta.exam_price)

  const [QuestionsBlock, setQuestionsBlock] = React.useState(true);
  const [QuestionsData, setQuestionsData] = React.useState(
    db_slug == slugs.db_slug_demo_exam ?
      JSON.parse(FetchData.meta.exam_question_text) :
      JSON.parse(FetchData.meta.exam_question_block_text)
  );
  const [ExamSubType, setExamSubType] = React.useState(
    db_slug == slugs.db_slug_buy_exam ?
      FetchData.meta.exam_sub_type : ""
  );
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(false);
  const [QuestionsEditData, setQuestionsEditData] = React.useState({});
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState();

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const EditInfo = (POSTID, slug, passSlug) => {
    setReload(true)
    console.log(QuestionsData)
    var data;

    if (slug == slugs.db_slug_demo_exam)
      data = {
        "title": Title,
        "status": "publish",
        "meta": {
          "exam_question_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType,
        }
      }
    else
      data = {
        "title": Title,
        "status": "publish",
        "meta": {
          "exam_question_block_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType,
          "exam_sub_type": ExamSubType,
          "exam_price": `${ExamPrice}`,
          "category_id": `${CategoryID}`,
          "category_name": CategoryName
        }
      }
    // else
    //   data = {
    //     "title": Title,
    //     "status": "publish",
    //     "meta": {
    //       "exam_question_block_text": JSON.stringify(QuestionsData),
    //       "exam_time": Time,
    //       "exam_details": Details,
    //       "available_unavailable": Available,
    //       "featured_image": FeaturedImage,
    //       "media_non_repeat": AudioSingle,
    //       "exam_type": ExamType
    //     }
    //   }

    if (POSTID === "")
      AdsPOSTApi(data, slug)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Exam Added Successfully")
          console.log(res.data)
          history.push(`${passSlug}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slug, POSTID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }

  const [CategoryModal, setCategoryModal] = React.useState(false)
  const [CategoryID, setCategoryID] = React.useState(FetchData.meta.category_id)
  const [CategoryName, setCategoryName] = React.useState(FetchData.meta.category_name)
  const [CategoriesList, setCategoriesList] = React.useState([])
  const GetAllCategories = () => {
    setReload(true)
    setCategoriesList([])
    AdsGETApiAuth({
      per_page: 100,
      _fields: "id,title,slug,meta"
    }, slugs.db_slug_category)
      .then((res) => {
        setCategoriesList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllCategories()
  }, [])

  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Exam Information</center></h4>

          <table>
            <tbody>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>

              <tr>
                <td className="col-3">Exam Time (in minutes)</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Time} placeholder="Exam Time"
                    onChange={(event) => { setTime(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Price</td>
                <td className="col-6">
                  <input className="input-common"
                    value={ExamPrice} placeholder="Exam Price"
                    onChange={(event) => { setExamPrice(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Details</td>
                <td className="col-6">
                  <textarea rows={5} className="input-common"
                    value={Details} placeholder="Exam Details"
                    onChange={(event) => { setDetails(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Featured Image</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={FeaturedImage}
                    setImageUrl={setFeaturedImage}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Audio Single</td>
                <td className="col-6">
                  <div style={{ display: "inline" }}>
                    <div style={{ display: "inline-block" }}>
                      <CustomButtonUnSelect setData={setAudioSingle} Data={AudioSingle} DataValue={"Single"} />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Available"} />
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Unavailable"} />
                </td>
              </tr>
              {(db_slug !== slugs.db_slug_demo_exam) &&
                <>
                  <tr>
                    <td className="col-3">Exam Category</td>
                    <td className="col-6"
                      onClick={() => {
                        setCategoryModal(!CategoryModal)
                      }}
                    >
                      <div className="row">
                        <div className="col-8">
                          <div className="input-common">
                            {CategoryName}&nbsp;
                          </div>
                        </div>
                        <div className="col-4"
                          onClick={() => {
                            setCategoryID("")
                            setCategoryName("")
                          }}
                        >
                          Clear
                        </div>
                      </div>
                    </td>
                  </tr>
                  {CategoryModal &&
                    <section className="modal" >
                      <div className="row">
                        <div className="col-md-3 col-1"></div>
                        <div className="col-md-6 col-10  shadow bg-white">
                          <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                            <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                              onClick={() => {
                                setCategoryModal(!CategoryModal)
                              }}
                            >X</div>
                            <table>
                              <tbody>
                                {CategoriesList.map((item, index) => (
                                  <tr className="row bg-white shadow" key={index}
                                    style={{ cursor: "pointer", border: "1px solid #999" }}
                                    onClick={() => {
                                      setCategoryID(`${item.id}`)
                                      setCategoryName(item.title.rendered)
                                      setCategoryModal(!CategoryModal)
                                    }}
                                  >
                                    <td className="col-1">
                                      {CategoryID !== item.id ?
                                        <i className="fa fa-square-o" />
                                        :
                                        <i className="fa fa-check-square-o" />
                                      }
                                    </td>
                                    <td className="col-2" >
                                      {item.id}
                                    </td>
                                    <td className="col-3">
                                      {item.title.rendered}
                                    </td>
                                    <td className="col-3">
                                      {item.meta.batch_start_date}
                                    </td>
                                    <td className="col-3">
                                      {item.meta.batch_end_date}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </section>
                  }
                </>
              }

            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo(ID, db_slug, webSlug)
                }}
              >
                Update Exam
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mb-3">
          <h4><center>Questions</center></h4>
          <div style={{ display: "inline", justifyContent: "flex-end" }}>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: QuestionsBlock ? "#000000" : "#444444", fontWeight: QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(true)
              }}
            >
              Block
            </div>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: !QuestionsBlock ? "#000000" : "#444444", fontWeight: !QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(false)
              }}
            >
              Table
            </div>
          </div>
          {/* {(CategoryName) && */}
          <div>
            <div className="row center m-2">
              <div className=" col-3" style={{ padding: "0 10px" }}>
                <div className="sign-in-button-4"
                  onClick={() => {
                    setQuestionsEditData({
                      "question_number": `${Object.keys(QuestionsData).length + 1}`,
                      "question": "",
                      "question_description": "",
                      "question_description_type": "text",
                      "question_media": "",
                      "question_media_type": "none",
                      "option_1": "",
                      "option_2": "",
                      "option_3": "",
                      "option_4": "",
                      "answer_description": "",
                      "correct_answer": "option 1",
                      "answer_media_type": "text",
                    })
                    setQuestionsEditKey(`item-${Object.keys(QuestionsData).length}`)
                    setQuestionsEditMode(true)
                  }}
                >
                  Add Question
                </div>
              </div>
            </div>
          </div>
          {/* } */}
          {QuestionsBlock ?
            <div>
              <div className="row m-4">
                <div className="col-6">
                  <div className="row">
                    {Object.values(QuestionsData).map((item, index) => {
                      if (index < Object.values(QuestionsData).length / 2)
                        return (
                          <div key={index} className="col-2 p-2 center"
                            style={{
                              margin: "5px",
                              backgroundColor: item.question !== "" ? "#fad4d4" : "#fafad4",
                              fontWeight: "bold",
                              border: "2px solid black",
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setQuestionsEditData(item)
                              setQuestionsEditKey(Object.keys(QuestionsData)[index])
                              setQuestionsEditMode(true)
                            }}
                          >
                            {item.question_number}
                          </div>
                        )
                    })}
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    {Object.values(QuestionsData).map((item, index) => {
                      if (index >= Object.values(QuestionsData).length / 2)
                        return (
                          <div key={index} className="col-2 p-2 center"
                            style={{
                              margin: "5px",
                              backgroundColor: item.question !== "" ? "#fad4d4" : "#fafad4",
                              fontWeight: "bold",
                              border: "2px solid black",
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setQuestionsEditData(item)
                              setQuestionsEditKey(Object.keys(QuestionsData)[index])
                              setQuestionsEditMode(true)
                            }}
                          >
                            {item.question_number}
                          </div>
                        )
                    })}
                  </div>
                </div>
              </div>
            </div>
            :
            <div style={{
              margin: "20px",
              // display: "none",
            }}
            >
              <div style={{ margin: "20px" }}>
                <div className="col-12 form-view mb-3 ques-print" style={{ padding: "20px" }}>
                  <div style={{ overflowX: "scroll", }}>
                    {Object.values(QuestionsData).map((oneQuestionData, index) => (
                      <section key={index} style={{
                        pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
                      }}
                        onClick={() => {
                          setQuestionsEditData(oneQuestionData)
                          setQuestionsEditKey(Object.keys(QuestionsData)[index])
                          setQuestionsEditMode(true)
                        }}
                      >
                        <div>
                          <div className="row"
                            style={{
                              border: "1px solid #999",
                              borderRadius: "5px",
                              margin: "5px",
                              padding: 0
                            }}>
                            <div className="col-md-8" >
                              <div>
                                <div style={{ padding: "5px 0 0 0" }}>
                                  <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                    {oneQuestionData.question}
                                  </h3>
                                  <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                    {oneQuestionData.question_number}.
                                  </h3>
                                </div>
                                <div className="question-description ">
                                  {oneQuestionData.question_description_type == "text" &&
                                    <div style={{
                                      fontSize: "15px",
                                      padding: "0 0 15px"
                                    }}>
                                      <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                    </div>
                                  }
                                  {oneQuestionData.question_description_type == "image" &&
                                    <>
                                      <div style={{ padding: "2px", textAlign: "center", }} >
                                        <img
                                          src={oneQuestionData.question_description}
                                          style={{
                                            height: "150px",
                                            width: "392px",
                                            objectFit: 'contain',
                                            padding: "2px",
                                            border: "0.5px solid #999",
                                          }}
                                        />
                                      </div>
                                    </>
                                  }
                                  {oneQuestionData.question_description_type == "audio" &&
                                    <div>
                                      <audio controls>
                                        {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                                        <source src={`${oneQuestionData.question_description}`} type="audio/mpeg" />
                                        <source src={`${oneQuestionData.question_description}`} type="audio/mp3" />
                                        <source src={`${oneQuestionData.question_description}`} type="audio/wav" />
                                        <source src={`${oneQuestionData.question_description}`} type="audio/ogg" />
                                        Your browser does not support the audio element.
                                      </audio>
                                      {/* <a href={oneQuestionData.question_description} target="_blank">
                                        <div style={{ cursor: "pointer" }} onClick={() => {
                                        }}>
                                          <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                            style={{ width: "20px", height: "20px" }}
                                          />
                                        </div>
                                      </a> */}
                                    </div>
                                  }
                                </div>


                                {oneQuestionData.question_media !== "" &&
                                  <div className="question-description col-10">
                                    {oneQuestionData.question_media_type == "text" &&
                                      <div style={{
                                        border: "0.5px solid #999",
                                        padding: "5px ",
                                        justifyContent: 'center',
                                        display: "flex"
                                      }}>
                                        <div style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                      </div>
                                    }
                                    {oneQuestionData.question_media_type == "image" &&
                                      <div style={{ textAlign: "center" }}
                                        onClick={() => {
                                        }}
                                      >

                                        <img
                                          src={oneQuestionData.question_media}
                                          style={{
                                            height: "150px",
                                            width: "392px",
                                            padding: "2px",
                                            objectFit: 'contain',
                                            border: "0.5px solid #999"
                                          }}
                                        />
                                      </div>
                                    }
                                    {oneQuestionData.question_media_type == "audio" &&
                                      <div style={{ cursor: "pointer" }} onClick={() => {
                                      }}>
                                        <audio controls>
                                          {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                                          <source src={`${oneQuestionData.question_media}`} type="audio/mpeg" />
                                          <source src={`${oneQuestionData.question_media}`} type="audio/mp3" />
                                          <source src={`${oneQuestionData.question_media}`} type="audio/wav" />
                                          <source src={`${oneQuestionData.question_media}`} type="audio/ogg" />
                                          Your browser does not support the audio element.
                                        </audio>
                                        {/* <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                style={{ width: "20px", height: "20px" }}
                              /> */}
                                      </div>
                                    }
                                  </div>
                                }
                              </div>
                            </div>
                            <div className={oneQuestionData.answer_media_type == "image" ? "col-md-12 ms-5" : "col-md-4"}
                              style={{ padding: "0" }}>
                              <div
                                className={oneQuestionData.answer_media_type == "image" ? "row" : ""}
                                style={{
                                }} >
                                <AnswerBlock index={1} DataValue={oneQuestionData.option_1} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 1"} />
                                <AnswerBlock index={2} DataValue={oneQuestionData.option_2} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 2"} />
                                <AnswerBlock index={3} DataValue={oneQuestionData.option_3} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 3"} />
                                <AnswerBlock index={4} DataValue={oneQuestionData.option_4} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 4"} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    ))}
                  </div>
                </div>
              </div>
            </div>

          }
        </div>
      </div>

      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mb-3">
          <h4><center>Make a copy to:</center></h4>
          <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"Student Exam"} />
          {db_slug == "daily_exam" &&
            <>
              {Object.keys(QuestionsData).length == 40 &&
                <>
                  <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"All Exam"} />
                  <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"Demo Exam"} />
                </>
              }
            </>
          }
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  if (CopyTo == "Batch Wise Exam")
                    EditInfo("", slugs.db_slug_daily_exam, slugs.daily_exam_details)
                  else if (CopyTo == "All Exam")
                    EditInfo("", slugs.db_slug_paid_exam, slugs.exam_details)
                  else if (CopyTo == "Student Exam")
                    EditInfo("", slugs.db_slug_buy_exam, slugs.buy_exam_details)
                  else
                    EditInfo("", slugs.db_slug_demo_exam, slugs.demo_exam_details)
                }}
              >
                Make a copy
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        QuestionsEditMode &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setQuestionsEditMode(false)
                    setQuestionsEditData([])
                    setQuestionsEditKey("")
                  }}
                >X</div>
                <ExamQuestionModal
                  oneQuestionData={QuestionsEditData}
                  keyValue={QuestionsEditKey}
                  AllQuestionData={QuestionsData}
                  setAllQuestionData={setQuestionsData}
                  PostExam={EditInfo}
                  setEditQuestion={setQuestionsEditMode}
                />
              </div>
            </div>
          </div>
        </section>
      }

      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}


const AnswerBlock = ({ index, DataType, DataValue, correct_answer, option }) => {
  return (
    <div
      className={DataType == "image" ? "col-6" : "col-12"}
      style={{
        borderLeft: option == correct_answer ? "2px solid green" : 0,
        display: 'flex',
        padding: "5px 10px",
      }}
    >
      <div style={{ marginRight: "5px", cursor: "pointer" }}>
        <div style={{
          border: option == correct_answer ? "2px solid green" : "2px solid black",
          backgroundColor: option == correct_answer ? "green" : "#fff",
          color: option == correct_answer ? "#fff" : "#000",
          borderRadius: "14px",
          width: "20px",
          height: "20px", display: "flex", justifyContent: "center", alignContent: "center",
          fontSize: "12px", fontWeight: "bold"
        }}>
          &nbsp;{index}&nbsp;
        </div>
      </div>
      <div style={{ fontSize: "16px" }} className="passive-option2">
        {DataType == "text" &&
          `${DataValue}`
        }
        {DataType == "image" &&
          <>
            <img
              src={DataValue}
              style={{
                width: "120px",
                border: "0.5px solid #000"
              }}
            />
          </>
        }
        {DataType == "audio" &&
          <div>
            <audio controls>
              {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
              <source src={`${DataValue}`} type="audio/mpeg" />
              <source src={`${DataValue}`} type="audio/mp3" />
              <source src={`${DataValue}`} type="audio/wav" />
              <source src={`${DataValue}`} type="audio/ogg" />
              Your browser does not support the audio element.
            </audio>
            <a href={DataValue} target="_blank">
              <div style={{ cursor: "pointer" }} onClick={() => { }}>
                <div>
                </div>
              </div>
            </a>
          </div>
        }
      </div>
    </div>
  )
}